import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { EventPayload } from '@azure/msal-browser';
import { ProfileHttpService } from './core/services/profile/profile-http.service';
import { ProfileService } from './core/services/profile/profile.service';
import { SignalRService } from './core/services/signalr/signalR.service';
import { TaskConductorService } from './protasks/services/task-conductor.service';
import { StashService } from './utilities/services/stash.service';

@Injectable({ providedIn: 'root' })
export class RedirectEventService {
	constructor(
		private _router: Router,
		private _msalService: MsalService,
		private _stashService: StashService,
		private _profileService: ProfileService,
		private _signalrService: SignalRService,
		private _taskConductor: TaskConductorService,
		private _profileHttpService: ProfileHttpService
	) {}

	handleInitializationStart() {
		const activeMsalAccount = this._msalService.instance.getAllAccounts()[0];
		const userProfile = this._profileService.myAccessProfile$.getValue();
		const localMsalAccount = this._profileService.msalAccount$.getValue();
		const activeTasks = this._taskConductor.getAllTasksInStore();
		if (!userProfile && !!activeMsalAccount) {
			this._profileHttpService.callMyAccessProfile().toPromise();
		}
		if (!localMsalAccount && !!activeMsalAccount) {
			this._profileService.setMsalAccount(activeMsalAccount);
		}
		if (!activeTasks.length && !!activeMsalAccount) {
			this._taskConductor.loadActiveTasks$().toPromise();
		}
	}

	handleLoginSuccess() {
		const activeAccount = this._msalService.instance.getAllAccounts()[0];
		this._profileService.setMsalAccount(activeAccount);
		this._msalService.instance.setActiveAccount(activeAccount);
		this._profileHttpService.callMyAccessProfile().toPromise();
		this._taskConductor.loadActiveTasks$().toPromise();
	}

	handleLogoutStart() {
		this._signalrService.stopSignalRConnection();
		this._profileService.clearProfileData();
		this._taskConductor.emptyTasksInStore();
	}

	handleAquireTokenSuccess(payload: EventPayload) {
		this._stashService.setIdToken(payload['idToken']);
		this._stashService.setAccessToken(payload['accessToken']);
		this._stashService.setOid(payload['idTokenClaims']['oid']);
		this._stashService.setName(payload['idTokenClaims']['name']);
	}

	handleCompleteInteraction() {
		const activeMsalAccount = this._msalService.instance.getAllAccounts()[0];
		if (!!activeMsalAccount) {
			this._signalrService.startSignalRConnection();
		} else {
			this._router.navigateByUrl('/home');
		}
	}
}
