import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ProtaskMessage, Protask } from '../models/domain.models';
import { ProtaskOperation, ProtaskUserRole } from '../models/enumerations';
import { TaskStoreService } from '../../protasks/services/task-store.service';
import { HelperService } from 'src/app/utilities/services/helper.service';
import { TaskConductorService } from 'src/app/protasks/services/task-conductor.service';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	constructor(
		private _snackbar: MatSnackBar,
		private _router: Router,
		private _zone: NgZone,
		private _taskStore: TaskStoreService,
		private _taskConductor: TaskConductorService,
		private _helperService: HelperService
	) {}

	notifyOnNewTaskMessageReceived(protaskMessage: ProtaskMessage): void {
		const receivedOrSubmitted = this._taskConductor.getNeededPathFromTaskMessage(protaskMessage);
		this._zone.run(() => {
			const snackbarRef = this._snackbar.open(
				`New comment received on task #${protaskMessage.protaskId} from ${protaskMessage.author}`,
				'View New Comment'
			);
			snackbarRef
				.onAction()
				.subscribe(() =>
					this._router.navigateByUrl(`/tasks/${receivedOrSubmitted}/${protaskMessage.protaskId}`)
				);
		});
	}

	notifyOnTaskUpdate(task: Protask, protaskOperation: ProtaskOperation): void {
		let message: string = '';
		const receivedOrSubmitted = task.userRole === ProtaskUserRole.Provider ? 'received' : 'submitted';
		switch (protaskOperation) {
			case ProtaskOperation.CreateProtask:
				message = `Received a new task #${task.id} from ${task.submitter}`;
				break;

			case ProtaskOperation.ClaimOpenProtask:
				message = `Task #${task.id} was claimed by ${task.provider}`;
				break;

			case ProtaskOperation.CompleteInProgressProtask:
				message = `Task #${task.id} was completed`;
				break;

			case ProtaskOperation.ResubmitCompletedProtask:
				message = `Task #${task.id} was resubmitted and is back in-progress`;
				break;

			case ProtaskOperation.CloseCompletedProtask:
			case ProtaskOperation.InstantCloseInProgressProtask:
				message = `Task #${task.id} is now closed`;
				break;

			case ProtaskOperation.CancelOpenProtask:
			case ProtaskOperation.InstantCancelInProgressProtask:
			case ProtaskOperation.AcknowledgeCancelPending:
				message = `Task #${task.id} was canceled`;
				break;

			case ProtaskOperation.CancelInProgressProtask:
				message = `Cancelation request has been made for task #${task.id}`;
				break;

			case ProtaskOperation.InstantClaim:
				message = `Task ${task.id} has been auto-assigned to ${task.provider}`;
				break;

			default:
				break;
		}

		const snackbarAction = this.getStateChangeAction(task);

		if (!!message.length) {
			this._zone.run(() => {
				const snackbarRef = this._snackbar.open(message, snackbarAction);
				snackbarRef
					.onAction()
					.subscribe(() => this._router.navigateByUrl(`/tasks/${receivedOrSubmitted}/${task.id}`));
			});
		}
	}

	private doesTaskExistInStore(task: Protask): boolean {
		const taskInStore = this._taskStore.getTaskById(task.id);
		return !!taskInStore ? true : false;
	}

	private isProtaskDetailPageOpenForTheRelatedProtask(protaskId: number) {
		return this._helperService.checkIfUserIsOnDetailsPage(protaskId);
	}

	private getStateChangeAction(task: Protask): string {
		const isUserOnDetailsPage = this.isProtaskDetailPageOpenForTheRelatedProtask(task.id);
		const isTaskInStore = this.doesTaskExistInStore(task);
		return isTaskInStore && !isUserOnDetailsPage ? 'View Task' : '';
	}
}
