import {
	AgeComparison,
	ProtaskCollaboration,
	ProtaskState,
	ProtaskUserRole,
	TaskCondition,
	TemplateFieldType,
} from './enumerations';

export interface ITaskTime {
	state: ProtaskState;
	openedAt: Date;
	slaMinutesToRespond: number;
	respondedAt?: Date;
	slaMinutesToComplete: number;
}

export class Protask implements ITaskTime {
	id: number;
	state: ProtaskState;
	stateText: string;
	openedAt: Date;
	respondedAt?: Date;
	submitter: string;
	submitterHash: string;
	slaMinutesToRespond: number;
	slaMinutesToComplete: number;
	service: string;
	channel: string;
	providerBusinessUnit: string;
	submitterBusinessUnit: string;
	provider: string;
	updatedAt: Date;
	isWatched? = false;
	userRole: ProtaskUserRole;
	lastTouched: Date;
	collabPermissions: ProtaskCollaboration;
	templateFields?: any[];
	locationFields?: any[];
	reference?: string;
	condition: TaskCondition;
	shouldBlink: boolean;
	expiry: Date;

	ageComparison(protask: Protask): AgeComparison {
		if (this.updatedAt < protask.updatedAt) return AgeComparison.Older;

		if (this.updatedAt === protask.updatedAt) return AgeComparison.Same;

		return AgeComparison.Newer;
	}
}

export class SlaStatusInfo {
	tracking: boolean;
	finished: boolean;
	startedAt?: Date;
	minutesAllowed: number;
	conditionIsOk: boolean;
	expiryTime: Date;
	unboundMinutesRemaining: number;
	humanTimeRemaining: string;
	minutesRemaining: number;
	percentRemaining: number;
	condition: string;
	conditionCSS: string;
	ranking: number;
}

export class ProtaskMessage {
	id: number;
	protaskId: number;
	time: Date;
	humanizedTime: string;
	text?: string;
	fileName?: string;
	contentType?: string;
	url?: string;
	authorHash: string;
	author: string;
	azureUserId?: string;
}

export class KvP<K, V> {
	public key: K;
	public value: V;

	constructor(key: K, value: V) {
		this.key = key;
		this.value = value;
	}
}

export class BusinessUnitLookup extends KvP<number, string> {}

export class ServiceChannelLookup extends KvP<number, string> {
	businessUnitId: number;
}

export class ServiceLookup extends KvP<number, string> {
	serviceChannelId: number;
	timer1: number;
	timer2: number;
	gpsMode: number;
	description: string;
	responseTime: string;
	allowedCompletionTime: string;
	template?: { templateId: number; templateFields: TemplateField[] };
}

export class TemplateField extends KvP<number, string> {
	label: string;
	ordinal: number;
	fieldId: number;
	datatype: number;
	isRequired: boolean;
	templateId: number;
	lookupId: number;
	fieldType: TemplateFieldType;
	maxCharacterLength: number;
	numberOfRows: number;
}

export class ServiceStructureLookup {
	businessUnits: BusinessUnitLookup[];
	channels: ServiceChannelLookup[];
	services: ServiceLookup[];
}

export class CreateProtaskFields {
	serviceId: number;
	reference: string;
	locationFields: {};
	textFields: {};
}

export class DashboardAPIData {
	ch: string; // Service Channel
	id: number; // Protask Id
	lT: string; // Last Time Updated
	rf: string; // Reference Message
	s1: number; // Number of minutes allowed by the SLA to RESPOND to the protask
	s2: number; // Number of minutes allowed by the SLA to COMPLETE this protask
	sb: string; // Submitters name
	so: string; // Submitters organization
	st: number; // Protask State
	sv: string; // Service name
	tO: string; // Time the Protask was opened
	tR: string; // Time Protask was claimed by a provider
	pr: string; // Provider Name
}

export class DashboardReportProtask implements ITaskTime {
	openedAt: Date;
	slaMinutesToRespond: number;
	respondedAt: Date;
	slaMinutesToComplete: number;
	serviceChannel: string;
	protaskId: number;
	lastTimeUpdated: Date;
	referenceMessage: string;
	submitter: string;
	submitterOrganization: string;
	state: ProtaskState;
	humanizedState: string;
	service: string;
	providerName: string;
	expiry: string;
}
